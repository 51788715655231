import React from "react";

type ArgType =
  | [unknown?]
  | [unknown?, unknown?]
  | [unknown?, unknown?, unknown?]
  | [unknown?, unknown?, unknown?, unknown?];

type AsyncFunction<A extends ArgType, V> = (...a: A) => Promise<V>;

export interface AsyncValue<A extends ArgType, V> {
  loading: boolean;
  value: V;
  fetch(...a: A): Promise<V>;
}

export function useRequestState<A extends ArgType, V>(
  request: AsyncFunction<A, V>,
  defaultValue: V = null
): AsyncValue<A, V> {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);

  function fetch(...a: A) {
    setLoading(true);
    return request(...a)
      .then(v => {
        setValue(v);
        setLoading(false);
        return v;
      })
      .catch((error: Error) => {
        setLoading(false);
        return Promise.reject(error);
      });
  }

  return { loading, value, fetch };
}
